import styles from "../../assets/scss/menu/LastFriends.module.scss";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import useClickOutside from "../ClickOutside";
import { getLastFriends } from "../../services/MainService";
import ClipLoader from "react-spinners/ClipLoader";

import { BsPersonFill } from "react-icons/bs";

const LastFriends = ({ lastFriendsOpen, setLastFriendsOpen, domElement }) => {
    const mounted = useRef(false);
    const [count, setCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const domElementRef = useClickOutside(() => setLastFriendsOpen(false));
    domElementRef.add(domElement);

    useEffect(() => { mounted.current = true; return () => mounted.current = false; }, []);
    useEffect(() => getLastFriendsFunc(), [lastFriendsOpen]);

    const getLastFriendsFunc = () => {
        if (!lastFriendsOpen) { return false; }
        setLoading(true);

        getLastFriends()
            .then(res => {
                if (!mounted.current) { return false; }
                setCount(res.count);
                setRows(res.rows);
                setLoading(false);
                document.getElementById("badge_last_friends").classList.remove("badge_active");
            })
            .catch(() => {
                if (!mounted.current) { return false; }
                setTimeout(() => getLastFriendsFunc(), 5000);
            });
    };

    return (<div ref={domElementRef.add} className={`${styles.last_friends} ${lastFriendsOpen ? styles.active : undefined}`}>
        <div className={styles.title}>Últimos Amigos</div>

        {loading ? <div className={styles.loading}><ClipLoader color="#1e0f2a" /></div> : <>
            {count > 0 ? <>
                <div className={styles.items}>
                    {rows.map(key => <div key={key.code} className={styles.item}>
                        <div className={styles.name}><span className={`${styles.user_status} ${key.lastAction ? "user_online" : "user_offline"}`}><BsPersonFill title={key.lastAction ? "Online" : "Offline"} /></span> {key.name}</div>
                        <div className={styles.register}>{key.register}</div>
                    </div>)}
                </div>

                {count > 5 && <div className={styles.footer}><Link to="/amigos" onClick={() => setLastFriendsOpen(false)}>Ver todos</Link></div>}
            </> : <div className={styles.none}>Nenhum amigo.</div>}
        </>}
    </div>);
};

export default LastFriends;