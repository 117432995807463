import styles from "../../assets/scss/menu/Topbar.module.scss";
import { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { numberFormat } from "../Main";
import LastFriends from "./LastFriends";
import SubMenu from "./SubMenu";
import { getTopbarData, getTopbarPll } from "../../services/MainService";

import { MdEqualizer, MdMarkunread, MdSettings } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";

const Topbar = ({ sidebarRef, menu }) => {
    const { pathname } = useLocation();
    const mounted = useRef(false);
    const lastFriendsRef = useRef();
    const subMenuRef = useRef();
    const [lastFriendsOpen, setLastFriendsOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    useEffect(() => { mounted.current = true; return () => mounted.current = false; }, []);
    useEffect(() => getTopbarDataFunc(), []);

    const getTopbarDataFunc = () => {
        getTopbarData()
            .then(res => {
                if (!mounted.current) { return false; }
                document.getElementById("name").innerHTML = res.name;
                toggleBadges(res.messagesBadge, res.lastFriendsBadge);
                setTimeout(() => getTopbarPllFunc(), 15000);
            })
            .catch(() => {
                if (!mounted.current) { return false; }
                setTimeout(() => getTopbarDataFunc(), 5000);
            });
    };

    const getTopbarPllFunc = () => getTopbarPll().then(res => { if (!mounted.current) { return false; } toggleBadges(res.messagesBadge, res.lastFriendsBadge); setTimeout(() => getTopbarPllFunc(), 15000); }).catch(() => { if (!mounted.current) { return false; } setTimeout(() => getTopbarPllFunc(), 15000); });
    const toggleBadges = (messagesBadge, lastFriendsBadge) => { document.getElementById("badge_messages").innerHTML = numberFormat(messagesBadge); document.getElementById("badge_last_friends").innerHTML = numberFormat(lastFriendsBadge); messagesBadge ? document.getElementById("badge_messages").classList.add("badge_active") : document.getElementById("badge_messages").classList.remove("badge_active"); lastFriendsBadge ? document.getElementById("badge_last_friends").classList.add("badge_active") : document.getElementById("badge_last_friends").classList.remove("badge_active"); };

    return (<div className={styles.topbar}>
        <div ref={sidebarRef} className={`${styles.sidebar_toggle} ${parseInt(menu.nav) === 2 ? styles.sidebar_toggle_active : undefined}`} onClick={() => { parseInt(menu.nav) === 1 ? menu.setNav(2) : menu.setNav(1); }}>
            <div className={styles.lines}><span></span><span></span><span></span></div>
        </div>

        <div className={styles.logo}><Link to="/painel"><MdEqualizer /><span className={styles.logo_txt}>ENTROU GANHOU</span></Link></div>

        <div className={styles.items}>
            <div className={styles.item}>
                <NavLink to="/mensagens" className={({ isActive }) => isActive ? styles.item_active : undefined}>
                    <MdMarkunread /><div id="badge_messages" className={`${styles.badge} ${styles.badge_messages}`}>0</div>
                </NavLink>
            </div>

            <div ref={lastFriendsRef} className={`${styles.item} ${lastFriendsOpen ? styles.item_active : undefined} ${pathname === "/amigos" ? styles.item_active : undefined}`} onClick={() => setLastFriendsOpen(!lastFriendsOpen)}>
                <BsPersonFill /><div id="badge_last_friends" className={`${styles.badge} ${styles.badge_last_friends}`}>0</div>
            </div>

            <div ref={subMenuRef} className={`${styles.item} ${subMenuOpen ? styles.item_active : undefined}`} onClick={() => setSubMenuOpen(!subMenuOpen)}>
                <MdSettings />
            </div>

            <div id="name" className={`${styles.item} ${styles.name}`}></div>
        </div>

        <LastFriends lastFriendsOpen={lastFriendsOpen} setLastFriendsOpen={setLastFriendsOpen} domElement={lastFriendsRef.current} />
        <SubMenu subMenuOpen={subMenuOpen} setSubMenuOpen={setSubMenuOpen} domElement={subMenuRef.current} />
    </div>);
};

export default Topbar;