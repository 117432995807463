import { useState, useRef } from "react";
import { getCookie } from "./Main";
import Topbar from "./menu/Topbar";
import Sidebar from "./menu/Sidebar";

const Container = ({ bars, content }) => {
    const getNav = () => getCookie("nav") || 1;
    const [nav, setNav] = useState(getNav);
    const sidebarRef = useRef();

    return (<>
        {bars > 0 && <div className="topbar"><Topbar sidebarRef={sidebarRef} menu={{ nav, setNav }} /></div>}

        <div className="container">
            {bars > 0 && <div className={`sidebar ${parseInt(nav) === 3 ? "sidebar_nav3" : undefined} ${parseInt(nav) === 4 ? "sidebar_nav4" : undefined}`}><Sidebar sidebarRef={sidebarRef.current} menu={{ nav, setNav }} /></div>}
            <div className={`content ${bars > 0 && parseInt(nav) === 3 ? "content_nav3" : undefined} ${bars > 0 && parseInt(nav) === 4 ? "content_nav4" : undefined}`}>{content}</div>
        </div>
    </>);
};

export default Container;