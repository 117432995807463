import { useEffect } from "react";

const useClickOutside = (handler) => {
    const domElement = {};
    domElement.elements = [];
    domElement.add = (e) => { if (e && !domElement.elements.includes(e)) { domElement.elements.push(e); } };

    useEffect(() => {
        const maybeHandler = (e) => {
            if (e.target.classList.value.includes("swal2")) { return false; }
            let useHandler = true;
            domElement.elements.map(r => r.contains(e.target) && (useHandler = false));
            if (useHandler) { handler(); }
        };
        document.addEventListener("mousedown", maybeHandler);
        return () => document.removeEventListener("mousedown", maybeHandler);
    });

    return domElement;
};

export default useClickOutside;