import "../assets/scss/Main.scss";
import React, { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { getCookie } from "../components/Main";
import Container from "../components/Container";

const Index = lazy(() => import("./Index"));
const Login = lazy(() => import("./Login"));
const Recover = lazy(() => import("./Recover"));
const Register = lazy(() => import("./Register"));
const Dashboard = lazy(() => import("./Dashboard"));
const Friends = lazy(() => import("./Friends"));
const Withdraw = lazy(() => import("./Withdraw"));
const Invite = lazy(() => import("./Invite"));
const Messages = lazy(() => import("./Messages"));
const UpdateName = lazy(() => import("./UpdateName"));
const UpdatePassword = lazy(() => import("./UpdatePassword"));
const UpdatePix = lazy(() => import("./UpdatePix"));
const MyAccount = lazy(() => import("./MyAccount"));

const HomePage = () => {
    const PrivateRoute = ({ children }) => getCookie("token") ? children : <Navigate to="/login" />;

    return (<Routes>
        <Route path="/" element={<React.Suspense fallback={null}><Container bars={0} content={<Index />} /></React.Suspense>} />
        <Route path="/login" element={<React.Suspense fallback={null}><Container bars={0} content={<Login />} /></React.Suspense>} />
        <Route path="/recuperar" element={<React.Suspense fallback={null}><Container bars={0} content={<Recover />} /></React.Suspense>} />
        <Route path="/recuperar/:recoverCode" element={<React.Suspense fallback={null}><Container bars={0} content={<Recover />} /></React.Suspense>} />
        <Route path="/cadastro" element={<React.Suspense fallback={null}><Container bars={0} content={<Register />} /></React.Suspense>} />
        <Route path="/c/:inviteCode" element={<React.Suspense fallback={null}><Container bars={0} content={<Register />} /></React.Suspense>} />
        <Route path="/painel" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><Dashboard /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/amigos" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><Friends /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/sacar" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><Withdraw /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/convidar" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><Invite /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/mensagens" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><Messages /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/minha-conta" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><MyAccount /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/alterar-nome" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><UpdateName /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/alterar-senha" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><UpdatePassword /></PrivateRoute>} /></React.Suspense>} />
        <Route path="/alterar-chave-pix" element={<React.Suspense fallback={null}><Container bars={1} content={<PrivateRoute><UpdatePix /></PrivateRoute>} /></React.Suspense>} />
    </Routes>);
};

export default HomePage;