import styles from "../../assets/scss/menu/SubMenu.module.scss";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import useClickOutside from "../ClickOutside";
import { postLogout } from "../../services/MainService";

import { IoPersonOutline } from "react-icons/io5";
import { BsPencil } from "react-icons/bs";
import { FiLock } from "react-icons/fi";
import { HiOutlineKey } from "react-icons/hi";
import { BiPowerOff } from "react-icons/bi";

const SubMenu = ({ subMenuOpen, setSubMenuOpen, domElement }) => {
    const domElementRef = useClickOutside(() => setSubMenuOpen(false));
    domElementRef.add(domElement);

    const [pageSubmit, setPageSubmit] = useState(true);
    const btnLogout = () => { if (!pageSubmit) { return false; } setPageSubmit(false); postLogout(); };

    return (<div ref={domElementRef.add} className={`${styles.sub_menu} ${subMenuOpen ? styles.active : undefined}`}>
        <div className={styles.items}>
            <NavLink to="/minha-conta" className={({ isActive }) => isActive ? `${styles.item} ${styles.item_active}` : styles.item} onClick={() => setSubMenuOpen(false)}><IoPersonOutline /> Minha Conta</NavLink>
            <NavLink to="/alterar-nome" className={({ isActive }) => isActive ? `${styles.item} ${styles.item_active}` : styles.item} onClick={() => setSubMenuOpen(false)}><BsPencil /> Alterar Nome</NavLink>
            <NavLink to="/alterar-senha" className={({ isActive }) => isActive ? `${styles.item} ${styles.item_active}` : styles.item} onClick={() => setSubMenuOpen(false)}><FiLock /> Alterar Senha</NavLink>
            <NavLink to="/alterar-chave-pix" className={({ isActive }) => isActive ? `${styles.item} ${styles.item_active}` : styles.item} onClick={() => setSubMenuOpen(false)}><HiOutlineKey /> Alterar Chave Pix</NavLink>
            <div className={styles.divider}></div>
            <div className={styles.item} onClick={() => { setSubMenuOpen(false); btnLogout(); }}><BiPowerOff className={styles.logout} /> Sair</div>
        </div>
    </div>);
};

export default SubMenu;