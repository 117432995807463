import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";

export const validateName = (name) => {
    if (!name) { return false; }
    name = name.toLowerCase();
    const regex = /^[a-záâãéêíîóôõúûç]{2,15}( [a-záâãéêíîóôõúûç]{2,15})*$/g.test(name);
    const chars = name.length;
    return { regex, chars, words: name.split(" ").length };
};

export const validateWhatsApp = (whatsapp) => whatsapp && /^[0-9]{10,11}$/g.test(whatsapp);

export const validatePassword = () => !getSimplePasswordStatus().some(r => r === false);

export const validateEmail = (email) => !email || !/^[a-z0-9_]{1,50}([.-][a-z0-9_]{1,50}){0,10}@([a-z0-9_]{1,50}(-[a-z0-9_]{1,50}){0,2}\.){1,3}[a-z]{2,20}$/gi.test(email) ? false : email.toLowerCase();

export const validateSlugCode = (code) => !code || !/^[a-z0-9]{5,50}$/gi.test(code) ? false : code.toString();

export const validateInviteCode = (code) => !code || !/^[a-z]{3,8}$/gi.test(code) ? false : code.toLowerCase();

export const validatePagination = (pagination) => !pagination || !/^[0-9]{1,10}$/g.test(pagination) || !parseInt(pagination) ? 1 : parseInt(pagination);

export const whatsappFormat = (whatsapp) => {
    const phone = whatsapp.replace(/[^0-9]/g, "").slice(0, 11);

    const area = phone.substring(0, 2);
    const start = phone.substring(2, 7);
    const end = phone.substring(7, 11);

    let formated = null;

    if (phone.length > 7) { formated = `(${area}) ${start}-${end}`; }
    else if (phone.length > 2) { formated = `(${area}) ${start}`; }
    else if (phone.length > 0) { formated = `(${area}`; }

    return { phone, formated };
};

export const dateFormat = (date, format) => {
    const fullDate = date.replace(".000Z", "").split("T");
    const yy = fullDate[0].split("-")[0];
    const mm = fullDate[0].split("-")[1];
    const dd = fullDate[0].split("-")[2];
    const HH = fullDate[1].split(":")[0];
    const MM = fullDate[1].split(":")[1];
    const SS = fullDate[1].split(":")[2];
    const mapDate = { mm, dd, yy, HH, MM, SS };
    return format.replace(/mm|dd|yy|HH|MM|SS/g, matched => mapDate[matched]);
};

export const timerFormat = (total) => {
    if (total < 1000) { return "00:00"; }
    // const days = Math.floor(total / (1000 * 60 * 60 * 24));
    // const hours = ("0" + Math.floor((total / (1000 * 60 * 60)) % 24)).slice(-2);
    const minutes = ("0" + Math.floor((total / 1000 / 60) % 60)).slice(-2);
    const seconds = ("0" + Math.floor((total / 1000) % 60)).slice(-2);
    return `${minutes}:${seconds}`;
};

export const setCookie = (name, value) => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 12);
    document.cookie = `${name}=${value};expires=${expires};domain=.entrouganhou.com;path=/;SameSite=Strict`;
    document.cookie = `${name}=${value};expires=${expires};domain=.lacolhost.com;path=/;SameSite=Strict`;
};

export const getCookie = (name) => document.cookie.split("; ").find(item => item.startsWith(`${name}=`))?.split("=")[1] || null;

export const deleteCookie = (name) => {
    if (!document.cookie.split("; ").some(item => item.startsWith(`${name}=`))) { return false; }
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.entrouganhou.com`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.lacolhost.com`;
};

export const login = () => { deleteCookie("token"); window.location.href = "/login"; };

export const popAlert = (title, text, icon = 3) => Swal.fire({ icon: icon === 1 ? "success" : icon === 2 ? "warning" : "error", title, text, customClass: { icon: "swal_icon", title: "swal_title", htmlContainer: "swal_text", confirmButton: "swal_button_default" } });

export const getPrice = (price) => Number(price.toString().slice(0, -2));

export const scroll = (e) => window.scrollTo({ top: document.getElementById(e).offsetTop, left: 0, behavior: "smooth" });

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));

export const numberFormat = (number) => number.toString().replace(/([0-9])(?=([0-9]{3})+(?![0-9]))/g, "$1.");

export const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const moneyFormat = (price) => price.toFixed(2).replace(".", ",").replace(/([0-9])(?=([0-9]{3})+(?![0-9]))/g, "$1.");

export const getSimplePasswordStatus = () => {
    const length = document.getElementById("password").value.length;
    const range = /^.{8,20}$/g.test(document.getElementById("password").value);
    const letter = /[a-z]+/gi.test(document.getElementById("password").value);
    const number = /[0-9]+/g.test(document.getElementById("password").value);
    return [length, range, letter, number];
};

export const getStrongPasswordStatus = () => {
    const length = document.getElementById("password").value.length;
    const range = /^.{8,20}$/g.test(document.getElementById("password").value);
    const lowercase = /[a-z]+/g.test(document.getElementById("password").value);
    const uppercase = /[A-Z]+/g.test(document.getElementById("password").value);
    const number = /[0-9]+/g.test(document.getElementById("password").value);
    const special = /[-~!?@#$%^&*()_+={}<>[\]\\/|.,:;`'"]+/g.test(document.getElementById("password").value);
    return [length, range, lowercase, uppercase, number, special];
};

export const getEmailStatus = () => document.getElementById("email").value.length ? true : false;

export const IndexLoading = ({ loaderColor = "#1e0f2a" }) => <div className="index_loading"><ClipLoader color={loaderColor} /></div>;

export const PageLoading = ({ loaderColor = "#1e0f2a" }) => <div className="page_loading"><ClipLoader color={loaderColor} /></div>;

export const SubmitLoading = () => <span className="form_status_submit"><PulseLoader color="#ffffff" size={7} /></span>;

export const Footer = () => <footer>&copy; 2024 Entrou Ganhou</footer>;