import styles from "../../assets/scss/menu/Sidebar.module.scss";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { setCookie } from "../Main";
import Overlay from "../Overlay";
import useClickOutside from "../ClickOutside";

import { BsThreeDots, BsFillBarChartFill, BsPersonFill } from "react-icons/bs";
import { MdPix, MdOutlineLogout } from "react-icons/md";
import { BiSolidPlusSquare } from "react-icons/bi";

const NavUrl = ({ url, icon, item, setNav }) => {
    const checkWindowSize = () => window.innerWidth < 1025 && setNav(1);
    return (<li><NavLink to={url} className={({ isActive }) => isActive ? styles.active : undefined} onClick={() => checkWindowSize()}>{icon} <span className={styles.item}>{item}</span></NavLink></li>);
};

const Sidebar = ({ sidebarRef, menu }) => {
    const [width, setWidth] = useState(window.innerWidth);

    const domElement = useClickOutside(() => parseInt(menu.nav) === 2 && menu.setNav(1));
    domElement.add(sidebarRef);

    const toggleMenu = () => { if (parseInt(menu.nav) === 1) { menu.setNav(2); } else if (parseInt(menu.nav) === 2) { menu.setNav(1); } else if (parseInt(menu.nav) === 3) { menu.setNav(4); } else if (parseInt(menu.nav) === 4) { menu.setNav(3); } };
    const handlerResize = () => setWidth(window.innerWidth);

    useEffect(() => { window.addEventListener("resize", handlerResize); return () => window.removeEventListener("resize", handlerResize); }, []);

    useEffect(() => {
        if (width < 1025) { if (parseInt(menu.nav) === 1) { menu.setNav(1); } else if (parseInt(menu.nav) === 2) { menu.setNav(2); } else { menu.setNav(1); } }
        else { if (parseInt(menu.nav) === 3) { menu.setNav(3); } else if (parseInt(menu.nav) === 4) { menu.setNav(4); } else { menu.setNav(4); } }
        setCookie("nav", menu.nav);
    }, [width, menu]);

    return (<>
        <div ref={domElement.add} className={`${styles.sidebar} ${parseInt(menu.nav) === 2 ? styles.sidebar_nav2 : undefined} ${parseInt(menu.nav) === 3 ? styles.sidebar_nav3 : undefined}`}>
            <nav>
                <ul>
                    <span className={styles.category}>{parseInt(menu.nav) !== 3 ? "Menu" : <BsThreeDots />}</span>
                    <NavUrl url="/painel" icon={<BsFillBarChartFill />} item="Painel" setNav={menu.setNav} />
                    <NavUrl url="/amigos" icon={<BsPersonFill />} item="Amigos" setNav={menu.setNav} />
                    <NavUrl url="/sacar" icon={<MdPix />} item="Sacar Saldo" setNav={menu.setNav} />
                    <NavUrl url="/convidar" icon={<BiSolidPlusSquare />} item="Convidar" setNav={menu.setNav} />
                </ul>

                <div className={styles.btn_toggle}><span onClick={() => toggleMenu()}><MdOutlineLogout /></span></div>
            </nav>
        </div>

        {parseInt(menu.nav) === 2 && <Overlay name="sidebar" />}
    </>);
};

export default Sidebar;