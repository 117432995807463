import axios from "./BaseService";
import { getCookie, login } from "../components/Main";
const API_URL = process.env.REACT_APP_API_URL;

export const postLogin = async (whatsapp, password, captcha = null) => {
    const apiUrl = `${API_URL}/site/login`;
    const { ip } = await (await fetch("https://api64.ipify.org/?format=json")).json();
    const response = await axios.post(apiUrl, { whatsapp, password, captcha, ip });
    return response.data;
};

export const postLogout = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/logout`;
    const headers = { "authorization": getCookie("token") };
    await axios.post(apiUrl, {}, { headers });
    login();
};

export const postRecover = async (whatsapp, captcha = null) => {
    const apiUrl = `${API_URL}/site/recover`;
    const { ip } = await (await fetch("https://api64.ipify.org/?format=json")).json();
    const response = await axios.post(apiUrl, { whatsapp, captcha, ip });
    return response.data;
};

export const postRecoverUpdate = async (recoverCode, password) => {
    const apiUrl = `${API_URL}/site/recover-update`;
    const response = await axios.post(apiUrl, { recoverCode, password });
    return response.data;
};

export const postRegister = async (name, whatsapp, password, inviteCode, captcha = null) => {
    const apiUrl = `${API_URL}/site/register`;
    const { ip } = await (await fetch("https://api64.ipify.org/?format=json")).json();
    const response = await axios.post(apiUrl, { name, whatsapp, password, inviteCode, captcha, ip });
    return response.data;
};

export const postDeleteMessages = async (deleteMessages, pagination, results) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/delete-messages`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, { deleteMessages, p: pagination, r: results }, { headers });
    return response.data;
};

export const postUpdateName = async (name) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-name`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, { name }, { headers });
    return response.data;
};

export const postUpdatePassword = async (passwordCurrent, password) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-password`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, { passwordCurrent, password }, { headers });
    return response.data;
};

export const postUpdatePix = async (type, key) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-pix`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, { type, key }, { headers });
    return response.data;
};

export const postUpdateInviteCode = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-invite-code`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, {}, { headers });
    return response.data;
};

export const postWithdraw = async (balance) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/withdraw`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.post(apiUrl, { balance }, { headers });
    return response.data;
};







export const getIndex = async () => {
    const { ip } = await (await fetch("https://api64.ipify.org/?format=json")).json();
    const apiUrl = `${API_URL}/site/index?ip=${ip}`;
    const response = await axios.get(apiUrl);
    return response.data;
};

export const getRecover = async (recoverCode) => {
    const apiUrl = `${API_URL}/site/recover/${recoverCode}`;
    const response = await axios.get(apiUrl);
    return response.data;
};

export const getTopbarData = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/topbar-data`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getTopbarPll = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/topbar-pll`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getLastFriends = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/last-friends`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getMessages = async (pagination, results) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/messages?p=${pagination}&r=${results}`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getMyAccount = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/my-account`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getUpdateName = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-name`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getUpdatePix = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/update-pix`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getDashboard = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/dashboard`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getFriends = async (pagination, results) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/friends?p=${pagination}&r=${results}`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getWithdraw = async (pagination, results) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/withdraw?p=${pagination}&r=${results}`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getWithdrawals = async (pagination, results) => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/withdrawals?p=${pagination}&r=${results}`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};

export const getInvite = async () => {
    if (!getCookie("token")) { return login(); }
    const apiUrl = `${API_URL}/auth/invite`;
    const headers = { "authorization": getCookie("token") };
    const response = await axios.get(apiUrl, { headers });
    return response.data;
};